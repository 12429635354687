import 'regenerator-runtime/runtime'
import 'core-js/stable'
import '../styles/main.scss'
import 'bootstrap'
import Confetti from './common/Confetti'
import Consultancy from './consultancy/Consultancy'
import CookieWall from './cookies/CookieWall'
import Account from './account/Account'
import Event from './event/Event'
import Resource from './resources/Resource'
import Search from './search/Search'
import Bookmark from './bookmark/Bookmark'
import { loadOrganizationEditor } from './account/LoadOrganizationEditor'
import { loadEventAttendeeStatusDialog } from './event/update-event-attendee-status/loadEventAttendeeStatusDialog'

document.addEventListener('DOMContentLoaded', function () {
  try {
    setActiveNavItem()
  } catch (e) {}
  new Consultancy()
  new CookieWall()
  new Bookmark()
  new Confetti('confettiId')
  let module = document.body.dataset.module
  if (module === 'account') {
    new Account()
  } else if (module === 'event') {
    new Event()
  } else if (module === 'article') {
    new Resource()
  } else if (module === 'search') {
    new Search()
  }
  if (document.getElementById('organization-editor')) {
    loadOrganizationEditor()
  }
  let eventDialog = document.getElementById('update-event-attendee-status')
  if (eventDialog) {
    loadEventAttendeeStatusDialog(eventDialog.dataset.event)
  }
})

function setActiveNavItem() {
  let module = document.body.dataset.module
  if (module === 'article') {
    document.getElementById('nav-resources').classList.add('active')
  } else if (module === 'directory') {
    document.getElementById('nav-network').classList.add('active')
  } else if (module === 'event') {
    document.getElementById('nav-events').classList.add('active')
  } else if (document.body.classList.contains('about')) {
    document.getElementById('nav-about').classList.add('active')
  } else if (document.body.classList.contains('newsletters')) {
    document.getElementById('nav-newsletters').classList.add('active')
  }
}
